<template>
  <div class="grid crud-demo">
    <Loader v-model="loading" />
    <div class="col-12">
      <div class="card">
        <Toast />
        <h2>Cierre Mensual</h2>
        <BasicFormToolbar
        @refresh="refresh" @save="save" @export="exportCSV"
        :actions="['refresh','save','export']" />
        <div class="grid">
          <div class="col-12">
                <Fieldset legend="Informacion General">
                  <div class="p-fluid formgrid grid">
                    <FormDropdown wrapperClass="field col-4" label="Año" 
                    v-model="entity.year" :options="years" optionLabel="text" optionValue="id"/>
                    <FormDropdown wrapperClass="field col-4" label="Mes" 
                    v-model="entity.month" :options="months" optionLabel="text" optionValue="id"/>
                    <FormDropdown wrapperClass="field col-4" label="Estatus" 
                    v-model="entity.status" :options="statuses" optionLabel="text" optionValue="id"/>
                  </div> 
                </Fieldset> <br>
                <Fieldset :toggleable="true" legend="Cierres">
                  <BasicDatatable 
                    :selectionMode="'single'" 
                    :headers="headers" 
                    :rows="entities" 
                  />
                </Fieldset>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  HeaderGrid,
  ErrorToast,
} from "../../../utilities/General";
import Loader from "../../../components/general/Loader.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
import FormDropdown from "../../../components/general/FormDropdown.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import Session from "../../../mixins/sessionMixin";
import { AccountingLedgerAccountClosure } from '../../../models/contabilidad/AccountingLedgerAccountClosure';
import moment from 'moment';
import { AccountingPolicy } from '../../../models/contabilidad/AccountingPolicy';

export default {
  mixins: [Session],
  data() {
    return {
      entity: null,
      filters: {},
      entities: [],
      years: [{id: 2022, text: 2022},
              {id: 2023, text: 2023}],
      months: [
        {id: 1, text: "Enero"},
        {id: 2, text: "Febrero"},
        {id: 3, text: "Marzo"},
        {id: 4, text: "Abril"},
        {id: 5, text: "Mayo"},
        {id: 6, text: "Junio"},
        {id: 7, text: "Julio"},
        {id: 8, text: "Agosto"},
        {id: 9, text: "Septiembre"},
        {id: 10, text: "Octubre"},
        {id: 11, text: "Noviembre"},
        {id: 12, text: "Diciembre"},
      ],
      headers: [
        new HeaderGrid("Año", "year"),
        new HeaderGrid("Mes", "month_name"),
        new HeaderGrid("Estatus", "status_name")
      ],
      statuses: [
        {id: 1, text: "ABIERTO"},
        {id: 2, text: "CERRADO"},
      ],
      loading: false,
    };
  },
  components: { BasicDatatable,FormDropdown,Loader,BasicFormToolbar },
  created() {
    this.entity = new AccountingLedgerAccountClosure(this.session);
    this.entity.year = new Date().getFullYear();
    this.entity.month = new Date().getMonth() + 1;
  },
  async mounted() {
    await this.refresh();
  },
  methods: {
    new() {
      this.entity = new AccountingLedgerAccountClosure(this.session);
      this.entity.year = new Date().getFullYear();
      this.entity.month = new Date().getMonth() + 1;
    },
    async exportCSV() {
      this.loading = true;
      try {
        await new AccountingPolicy().export({
          branch: this.session.branch,
          company: this.session.company,
          initial_date: moment(this.entity.initial_date).format(),
          final_date: moment(this.entity.final_date).format(),
          id_accounting_policy_type: this.entity.id_accounting_policy_type,
          status: this.entity.status
        }, "polizas_export.xlsx");
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async save() {
      this.loading = true;
      try {
        if (!this.entity.id) {
          let newEntity = await this.entity.save();
          newEntity.status_name = newEntity.status == 1 ? "ABIERTO" : (newEntity.status == 2 ? "CERRADO" : "");
          newEntity.month_name = this.months.find(x => x.id == newEntity.month).text;
          this.entities.push(newEntity);
        }else {
          let updatedEntity = await this.entity.update();
          updatedEntity.status_name = updatedEntity.status == 1 ? "ABIERTO" : (updatedEntity.status == 2 ? "CERRADO" : "");
          updatedEntity.month_name = this.months.find(x => x.id == updatedEntity.month).text;

          this.entities[this.entities.findIndex(x => x.id == updatedEntity.id)] = updatedEntity;
        }
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
    async refresh() {
      this.loading = true;
      try {
        this.entities = await this.entity.all();
        this.entities = this.entities.map(x => {
          return {
            ...x,
            status_name: x.status == 1 ? "ABIERTO" : (x.status == 2 ? "CERRADO" : ""),
            month_name: this.months.find(month => month.id == x.month).text
          }
        })
      } catch (error) {
        this.$toast.add(new ErrorToast(error));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 960px) {
  ::v-deep(.p-toolbar) {
    flex-wrap: wrap;

    .p-button {
      margin-bottom: 0.25rem;
    }
  }
}
</style>
