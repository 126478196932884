import {
    Abstract
} from './Abstract';

import axios from 'axios';
var fileDownload = require('js-file-download');

export class AccountingLedgerAccountClosure extends Abstract {
    constructor(session) {
        super('accounting/AccountingLedgerAccountClosure', session);
        this.year = null;
        this.month = null;
        this.status = 2;
    }

    async balancePDF(body) {
        let response = await axios({
                method: "post",
                url: this.controller  + "/balance/pdf",
                responseType: "blob",
                data: body
            });
        fileDownload(response.data, 'BalanceGeneral.pdf');
        return response.data;
    }

    async balance(year, month) {
        let response = await axios.get(this.controller + "/balance/" + year + "/" + month, {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }

}